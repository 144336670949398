import { useTranslation } from 'react-i18next';
import { Box, Container, Typography } from '@mui/material';

import './Footer.scss';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      className="footer"
      sx={{ bgcolor: 'primary.main', color: 'white', py: 2 }}
    >
      <Container maxWidth="lg">
        <Typography variant="body2" align="center">
          © {currentYear} RockRoutes. {t('footer.allRightsReserved')}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
