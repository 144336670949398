import { Container, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Navbar from 'components/Navbar/Navbar';
import Footer from 'components/Footer/Footer';
import FeaturedRoutes from './FeaturedRoutes/FeaturedRoutes';

import climbing from 'assets/home/climbing.webp';
import './Home.scss';

const aboutStyles = {
  title: {
    fontSize: '3rem',
    fontWeight: 'bold',
  },
};

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="home">
      <Navbar />

      <Container className="home__container" fixed>
        <Box component="section" className="home__hero">
          <img src={climbing} className="home__hero-image" alt="climbing" />
        </Box>

        <Box component="section" className="home__about">
          <Typography variant="h1" sx={aboutStyles.title} gutterBottom>
            {t('home.about.title')}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {t('home.about.intro')}
          </Typography>
          <Typography paragraph>{t('home.about.passions')}</Typography>
          <Typography paragraph>{t('home.about.community')}</Typography>
          <Typography paragraph>{t('home.about.callToAction')}</Typography>
        </Box>

        <FeaturedRoutes />
      </Container>

      <Footer />
    </div>
  );
};

export default Home;
