import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { camelCase } from 'lodash';

import climbingWall from 'assets/climbing/climbingWall.png';
import crashpad from 'assets/climbing/crashpad.png';
import harness from 'assets/climbing/harness.png';
import hangboard from 'assets/climbing/hangboard.png';

import './Activities.scss';

const activitiesIcons = {
  bouldering: crashpad,
  sport: harness,
  speed: climbingWall,
  trainingArea: hangboard,
};

const Activities = ({ activities }) => {
  const { t } = useTranslation();

  return (
    <Grid container className="activities">
      {activities.map((activity) => (
        <Grid key={activity} item xs={12} sm={6} md={3}>
          <Card className="activities__card">
            <CardMedia component="div" className="activities__card-image">
              <img src={activitiesIcons[camelCase(activity)]} alt={activity} />
            </CardMedia>

            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {t(`gym.activities.${activity}`)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Activities;
