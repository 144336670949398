import httpClient from 'api/httpClient';
import languagesInterceptor from 'api/interceptors/languagesInterceptor';

languagesInterceptor();

const fetchGym = async (id) => {
  const response = await httpClient.get(`/gyms/${id}`);
  return response.data.gym;
};

export { fetchGym };
