import { useState, useEffect, useCallback } from 'react';

import { fetchZoneMarkers, fetchGymMarkers } from 'services/markersService';
import mapConfig from 'pages/map/constants/mapConfig';

const useMap = (map) => {
  const { center } = mapConfig;

  const [zoneMarkers, setZoneMarkers] = useState([]);
  const [gymMarkers, setGymMarkers] = useState([]);
  const [error, setError] = useState();

  const setCurrentLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        if (!map) return;

        const { lat, lng } = map.getCenter();
        if (center.lat != lat() || center.lng != lng()) return;

        map.setCenter({ lat: latitude, lng: longitude });
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const loadZoneMarkers = useCallback(async () => {
    try {
      const response = await fetchZoneMarkers();
      setZoneMarkers(response);
    } catch (error) {
      setError(true);
      console.log(error.response?.status);
    }
  }, []);

  const loadGymMarkers = useCallback(async () => {
    try {
      const response = await fetchGymMarkers();
      setGymMarkers(response);
    } catch (error) {
      setError(true);
      console.log(error.response?.status);
    }
  }, []);

  useEffect(() => {
    loadZoneMarkers();
    loadGymMarkers();
    setCurrentLocation();
  }, [loadZoneMarkers, loadGymMarkers, setCurrentLocation]);

  return {
    setCurrentLocation,
    zoneMarkers,
    setZoneMarkers,
    gymMarkers,
    setGymMarkers,
    error,
    setError,
  };
};

export default useMap;
