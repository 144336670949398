import httpClient from 'api/httpClient';
import languagesInterceptor from 'api/interceptors/languagesInterceptor';

languagesInterceptor();

const fetchZoneMarkers = async () => {
  const response = await httpClient.get('/zones');
  return response.data.zones;
};

const fetchGymMarkers = async () => {
  const response = await httpClient.get('/gyms');
  return response.data.gyms;
};

export { fetchZoneMarkers, fetchGymMarkers };
