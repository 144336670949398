import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Navbar from 'components/Navbar/Navbar';
import Breadcrumbs from 'pages/gym/components/Breadcrumbs/Breadcrumbs';
import Footer from 'components/Footer/Footer';
import Carousel from 'components/Carousel/Carousel';
import Contact from 'pages/gym/components/Contact/Contact';
import Schedule from 'pages/gym/components/Schedule/Schedule';

import useGym from 'pages/gym/hooks/useGym';

import './Gym.scss';
import Activities from './Activities/Activities';

const gymStyles = {
  title: {
    fontSize: '3rem',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
};

const Gym = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    name,
    countryId,
    countryName,
    stateId,
    stateName,
    marker: { lat, lng },
    description,
    email,
    address,
    phone,
    website,
    facebookUrl,
    instagramUrl,
    xUrl,
    youtubeUrl,
    operatingHours,
    climbingTypes,
    photos,
    // photos360,
    // error
  } = useGym(id);

  return (
    <div className="gym">
      <Navbar />

      <Container>
        <Grid container alignItems="center">
          <Grid xs={12}>
            <Breadcrumbs
              countryName={countryName}
              countryId={countryId}
              stateName={stateName}
              stateId={stateId}
              gymName={name}
            />
          </Grid>

          <Grid xs={12}>
            <Typography variant="h1" sx={gymStyles.title}>
              {name}
            </Typography>
          </Grid>

          <Grid xs={12}>
            <Carousel className="gym__carousel" photos={photos} />
          </Grid>

          <Grid xs={12}>
            <Typography sx={gymStyles.subtitle} variant="h2" gutterBottom>
              {t('gym.description')}
            </Typography>
            <Typography paragraph>{description}</Typography>
          </Grid>

          <Grid xs={12}>
            <Activities activities={climbingTypes} />
          </Grid>

          <Grid xs={12}>
            <Schedule schedule={operatingHours} />
          </Grid>

          <Grid xs={12}>
            <Contact
              address={address}
              mapsUrl="#"
              email={email}
              phone={phone}
              website={website}
              facebookUrl={facebookUrl}
              instagramUrl={instagramUrl}
              xUrl={xUrl}
              youtubeUrl={youtubeUrl}
              lat={lat}
              lng={lng}
            />
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </div>
  );
};

export default Gym;
