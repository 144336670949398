import Navbar from 'components/Navbar/Navbar';
import MarkersMap from './MarkersMap/MarkersMap';

const Map = () => {
  return (
    <>
      <Navbar />
      <MarkersMap />
    </>
  );
};

export default Map;
