import { MarkerF } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';

import mapConfig from 'pages/map/constants/mapConfig';

import './GymMarkers.scss';
import gymMarkerImg from 'assets/map/gyms-marker.png';

const GymMarkers = ({ gyms, clusterer }) => {
  const { gymMarkerLabel, gymMarkerIcon } = mapConfig;
  const navigate = useNavigate();

  const handleGymClick = (id) => {
    navigate(`/gyms/${id}`);
  };

  return (
    <>
      {gyms.map(({ id, name, marker }) => (
        <MarkerF
          key={id}
          position={marker}
          clusterer={clusterer}
          label={{ ...gymMarkerLabel, text: name }}
          icon={{ ...gymMarkerIcon, url: gymMarkerImg }}
          clickable
          onClick={() => handleGymClick(id)}
        />
      ))}
    </>
  );
};

export default GymMarkers;
