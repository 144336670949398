export default {
  center: {
    lat: 23.3266,
    lng: -105.54305562247826,
  },
  zoom: 6,
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeId: 'hybrid',
  gymMarkerLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#fff',
    className: 'gym-markers__label',
  },
  gymMarkerIcon: {
    scaledSize: {
      width: 39,
      height: 56,
    },
  },
  zoneMarkerLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#fff',
    className: 'zone-markers__label',
  },
  zoneMarkerIcon: {
    scaledSize: {
      width: 39,
      height: 56,
    },
  },
};
