import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Home from 'pages/home/components/Home';
import Map from 'pages/map/components/Map';
import Gym from 'pages/gym/components/Gym';

import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/map" element={<Map />} />
        <Route path="/gyms/:id" element={<Gym />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
