import { useTranslation } from 'react-i18next';

import { Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YoutubeIcon from '@mui/icons-material/YouTube';
import MapIcon from '@mui/icons-material/Map';
import XIcon from '@mui/icons-material/X';

import MiniMap from 'pages/gym/components/MiniMap/MiniMap';
import './Contact.scss';

const Contact = ({
  email,
  address,
  phone,
  website,
  facebookUrl,
  instagramUrl,
  xUrl,
  youtubeUrl,
  mapsUrl,
  lat,
  lng,
}) => {
  const { t } = useTranslation();

  return (
    <div className="contact">
      <Grid container>
        <Grid xs={12}>
          <Typography variant="h6" gutterBottom>
            Contact
          </Typography>
        </Grid>

        <Grid
          xs={12}
          md={4}
          className="contact__info"
          sx={{
            order: { xs: 1, md: 0 },
          }}
        >
          <ul className="contact__links">
            <li className="contact__link">
              <LocationOnIcon className="contact__link-icon" />
              <Typography className="contact__link-url" variant="body1">
                {address}
              </Typography>
            </li>

            {email && (
              <li className="contact__link">
                <span>
                  <EmailIcon className="contact__link-icon" />
                </span>
                <Typography className="contact__link-url" variant="body1">
                  {email}
                </Typography>
              </li>
            )}

            {phone && (
              <li className="contact__link">
                <PhoneIcon className="contact__link-icon" />
                <Typography className="contact__link-url" variant="body1">
                  {phone}
                </Typography>
              </li>
            )}

            {website && (
              <li className="contact__link">
                <LanguageIcon className="contact__link-icon" />
                <a href={website} target="_blank" rel="noreferrer">
                  <Typography className="contact__link-url" variant="body1">
                    {website}
                  </Typography>
                </a>
              </li>
            )}

            {facebookUrl && (
              <li className="contact__link">
                <FacebookIcon className="contact__link-icon" />
                <a href={facebookUrl} target="_blank" rel="noreferrer">
                  <Typography className="contact__link-url" variant="body1">
                    {facebookUrl}
                  </Typography>
                </a>
              </li>
            )}

            {instagramUrl && (
              <li className="contact__link">
                <InstagramIcon className="contact__link-icon" />
                <a href={instagramUrl} target="_blank" rel="noreferrer">
                  <Typography className="contact__link-url" variant="body1">
                    {instagramUrl}
                  </Typography>
                </a>
              </li>
            )}

            {xUrl && (
              <li className="contact__link">
                <XIcon className="contact__link-icon" />
                <a href={xUrl} target="_blank" rel="noreferrer">
                  <Typography className="contact__link-url" variant="body1">
                    {xUrl}
                  </Typography>
                </a>
              </li>
            )}

            {youtubeUrl && (
              <li className="contact__link">
                <YoutubeIcon className="contact__link-icon" />
                <a href={youtubeUrl} target="_blank" rel="noreferrer">
                  <Typography className="contact__link-url" variant="body1">
                    {youtubeUrl}
                  </Typography>
                </a>
              </li>
            )}
          </ul>

          <Button
            className="contact__open-map-button"
            variant="contained"
            color="primary"
            href={mapsUrl}
            startIcon={<MapIcon />}
            target="_blank"
            sx={{
              margin: {
                xs: '0',
                md: '0 3rem',
              },
            }}
          >
            {t('gym.contact.openMap')}
          </Button>
        </Grid>

        <Grid xs={12} md={8}>
          <MiniMap lat={lat} lng={lng} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
