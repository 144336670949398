import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from '@mui/material';

import './FeaturedRoutes.scss';

const featuredRoutesStyles = {
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
};

const FeaturedRoutes = () => {
  const { t } = useTranslation();
  const routes = [
    {
      id: 1,
      title: 'Route 1',
      description: 'Description 1',
      thumbnail: 'https://picsum.photos/300/200',
    },
    {
      id: 2,
      title: 'Route 2',
      description: 'Description 2',
      thumbnail: 'https://picsum.photos/300/200',
    },
    {
      id: 3,
      title: 'Route 3',
      description: 'Description 3',
      thumbnail: 'https://picsum.photos/300/200',
    },
    {
      id: 4,
      title: 'Route 4',
      description: 'Description 4',
      thumbnail: 'https://picsum.photos/300/200',
    },
  ];

  return (
    <Box component="section" className="home__featured-routes">
      <Typography variant="h2" sx={featuredRoutesStyles.title} gutterBottom>
        {t('home.featuredRoutes.title')}
      </Typography>

      <Grid container spacing={4}>
        {routes.map(({ id, title, description, thumbnail }) => {
          return (
            <Grid xs={12} md={6} lg={3} key={id}>
              <Card>
                <CardActionArea>
                  {' '}
                  {/* research if this element is usefor for onClick or link action */}
                  <CardMedia
                    component="img"
                    height="140"
                    image={thumbnail}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default FeaturedRoutes;
