import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';

import miniMapConfig from 'pages/gym/constants/miniMapConfig';

import './MiniMap.scss';

const LIBRARIES = ['places'];

const {
  zoom: defaultZoom,
  streetViewControl,
  fullscreenControl,
  defaultCenter,
} = miniMapConfig;

const MiniMap = ({ lat, lng }) => {
  const [map, setMap] = useState(null);

  let center = lat && lng ? { lat, lng } : defaultCenter;
  let zoom = defaultZoom;

  const handleZoomChanged = () => {
    if (map) zoom = map.getZoom();
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    LIBRARIES,
  });

  if (loadError) {
    // TODO: Add error component
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <div className="mini-map__container">
        <CircularProgress className="mini-map__loading" />
      </div>
    );
  }

  return (
    <GoogleMap
      mapContainerClassName="mini-map__container"
      onZoomChanged={handleZoomChanged}
      onLoad={(e) => setMap(e)}
      options={{
        center,
        zoom,
        streetViewControl,
        fullscreenControl,
      }}
    ></GoogleMap>
  );
};

export default MiniMap;
