/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Snackbar } from '@mui/material';
import {
  GoogleMap,
  MarkerClustererF,
  useLoadScript,
} from '@react-google-maps/api';

import ZoneMarkers from 'pages/map/components/ZoneMarkers/ZoneMarkers';
import GymMarkers from 'pages/map/components/GymMarkers/GymMarkers';
import useMap from 'pages/map/hooks/useMap';
import mapConfig from 'pages/map/constants/mapConfig';

import './MarkersMap.scss';

const LIBRARIES = ['places'];

const {
  center: defaultCenter,
  zoom: defaultZoom,
  streetViewControl,
  fullscreenControl,
  mapTypeId,
} = mapConfig;

const MarkersMap = () => {
  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  const { zoneMarkers, gymMarkers, error, setError } = useMap(map);

  let center = defaultCenter;
  let zoom = defaultZoom;

  const handleZoomChanged = () => {
    if (map) zoom = map.getZoom();
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    LIBRARIES,
  });

  if (loadError) {
    // TODO: Add error component
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <div className="markers-map__container">
        <CircularProgress className="markers-map__loading" />
      </div>
    );
  }

  return (
    <>
      <GoogleMap
        mapContainerClassName="markers-map__container"
        onZoomChanged={handleZoomChanged}
        onLoad={(e) => setMap(e)}
        options={{
          center,
          zoom,
          streetViewControl,
          fullscreenControl,
          mapTypeId,
        }}
      >
        <MarkerClustererF gridSize={60}>
          {(clusterer) => {
            return (
              <>
                <ZoneMarkers zones={zoneMarkers} clusterer={clusterer} />
                <GymMarkers gyms={gymMarkers} clusterer={clusterer} />
              </>
            );
          }}
        </MarkerClustererF>
      </GoogleMap>

      {error && (
        <Snackbar
          open={error}
          autoHideDuration={5000}
          onClose={() => setError(false)}
          message={t('map.errors.markers')}
        />
      )}
    </>
  );
};

export default MarkersMap;
