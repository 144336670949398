import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const NavbarProfileMenu = ({
  anchorEl,
  menuId,
  isMenuOpen,
  handleMenuClose,
}) => {
  const { t } = useTranslation();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>{t('navbar.profile')}</MenuItem>
      <MenuItem onClick={handleMenuClose}>{t('navbar.myAccount')}</MenuItem>
    </Menu>
  );
};

export default NavbarProfileMenu;
