import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon, ListItemText } from '@mui/material';

import euaFlagIcon from 'assets/navbar/eua-flag.png';
import mxFlagIcon from 'assets/navbar/mx-flag.png';

const translationsIconStyle = {
  width: '20px',
  height: '20px',
};

const SPANISH = 'es';
const ENGLISH = 'en';

const NavbarTranslationsMenu = ({
  anchorEl,
  menuId,
  isMenuOpen,
  handleMenuClose,
}) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    handleMenuClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => changeLanguage(SPANISH)}>
        <ListItemIcon sx={translationsIconStyle}>
          <img src={mxFlagIcon} alt="Mexico flag" />
        </ListItemIcon>
        <ListItemText>{t('navbar.idioms.es')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => changeLanguage(ENGLISH)}>
        <ListItemIcon sx={translationsIconStyle}>
          <img src={euaFlagIcon} alt="United States flag" />
        </ListItemIcon>
        <ListItemText>{t('navbar.idioms.en')}</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default NavbarTranslationsMenu;
