/* eslint-disable no-unused-vars */
import { useState } from 'react';

import MuiCarousel from 'react-material-ui-carousel';
import { Box, Skeleton } from '@mui/material';

import './Carousel.scss';

const TRANSITION_INTERVAL = 8000;

const Carousel = ({ className, photos }) => {
  const [loadedImages, setLoadedImages] = useState(0);

  const handleImageLoad = () => {
    setLoadedImages((prev) => prev + 1);
  };

  const loadingImages = loadedImages < photos.length;

  return (
    <div className="carousel">
      <MuiCarousel
        className={`carousel__mui-carousel ${className}`}
        interval={TRANSITION_INTERVAL}
        autoPlay={!loadingImages}
      >
        {photos.map((photo) => (
          <img key={photo} src={photo} onLoad={handleImageLoad} alt="Gym" />
        ))}
      </MuiCarousel>

      {loadingImages && (
        <Box className="carousel__loader">
          <Skeleton
            variant="rectangular"
            animation="wave"
            className="carousel__skeleton"
          />
        </Box>
      )}
    </div>
  );
};

export default Carousel;
