import { useEffect, useState, useCallback } from 'react';
import { fetchGym } from 'pages/gym/services/gymsService';

const useGym = (id) => {
  const [name, setName] = useState('');
  const [countryId, setCountryId] = useState(null);
  const [countryName, setCountryName] = useState('');
  const [stateId, setStateId] = useState(null);
  const [stateName, setStateName] = useState('');
  const [marker, setMarker] = useState({});
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [operatingHours, setOperatingHours] = useState({});
  const [climbingTypes, setClimbingTypes] = useState([]);
  const [facebookUrl, setFacebookUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [xUrl, setXUrl] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [photos, setPhotos] = useState([]);
  const [photos360, setPhotos360] = useState([]);
  const [error, setError] = useState(false);

  const setGymStates = (gym) => {
    setName(gym.name);
    setCountryId(gym.country.id);
    setCountryName(gym.country.name);
    setStateId(gym.state.id);
    setStateName(gym.state.name);
    setMarker(gym.marker);
    setDescription(gym.description);
    setEmail(gym.email);
    setAddress(gym.address);
    setPhone(gym.phone);
    setWebsite(gym.website);
    setOperatingHours(gym.operating_hours);
    setClimbingTypes(gym.climbing_types);
    setFacebookUrl(gym.facebook_url);
    setInstagramUrl(gym.instagram_url);
    setXUrl(gym.x_url);
    setYoutubeUrl(gym.youtube_url);
    setPhotos(gym.photos);
    setPhotos360(gym.photos_360);
  };

  const loadGym = useCallback(async () => {
    try {
      const gym = await fetchGym(id);
      setGymStates(gym);
    } catch (error) {
      setError(true);
    }
  }, [id]);

  useEffect(() => {
    loadGym();
  }, [loadGym]);

  return {
    name,
    setName,
    countryId,
    setCountryId,
    countryName,
    setCountryName,
    stateId,
    setStateId,
    stateName,
    setStateName,
    marker,
    setMarker,
    description,
    setDescription,
    email,
    setEmail,
    address,
    setAddress,
    phone,
    setPhone,
    website,
    setWebsite,
    operatingHours,
    setOperatingHours,
    climbingTypes,
    setClimbingTypes,
    facebookUrl,
    setFacebookUrl,
    instagramUrl,
    setInstagramUrl,
    xUrl,
    setXUrl,
    youtubeUrl,
    setYoutubeUrl,
    photos,
    setPhotos,
    photos360,
    setPhotos360,
    error,
  };
};

export default useGym;
