import { MarkerF } from '@react-google-maps/api';

import mapConfig from 'pages/map/constants/mapConfig';

import './ZoneMarkers.scss';
import zoneMarkerImg from 'assets/map/zones-marker.png';

const ZoneMarkers = ({ zones, clusterer }) => {
  const { zoneMarkerIcon, zoneMarkerLabel } = mapConfig;
  return (
    <>
      {zones.map(({ id, name, marker }) => (
        <MarkerF
          key={id}
          position={marker}
          clusterer={clusterer}
          label={{ ...zoneMarkerLabel, text: name }}
          icon={{ ...zoneMarkerIcon, url: zoneMarkerImg }}
          title={name}
          clickable
        />
      ))}
    </>
  );
};

export default ZoneMarkers;
