import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material';

const daysOrder = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const isOperating = (day) => day.open && day.close;

const Schedule = ({ schedule }) => {
  const { t } = useTranslation();

  if (isEmpty(schedule)) {
    return <Skeleton variant="rectangular" />;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        maxWidth: '100%',
        overflowX: 'auto',
        margin: 'auto',
        marginTop: 4,
        marginBottom: 4,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t('gym.schedule.title')}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {daysOrder.map((day) => (
                <TableCell key={day} align="center" sx={{ fontWeight: 'bold' }}>
                  {t(`gym.schedule.${day}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {daysOrder.map((day) => (
                <TableCell key={day} align="center">
                  {isOperating(schedule[day])
                    ? `${schedule[day].open} - ${schedule[day].close}`
                    : t('gym.schedule.closed')}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Schedule;
