import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const breadcrumbStyle = {
  marginTop: '2rem',
};

const Breadcrumbs = ({ countryName, stateName, name }) => {
  // TODO: Implement countryId and stateId to make the links work

  return (
    <MuiBreadcrumbs
      sx={breadcrumbStyle}
      className="gym__breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link underline="hover" color="inherit" href="#">
        {countryName}
      </Link>
      <Link underline="hover" color="inherit" href="#">
        {stateName}
      </Link>
      <Typography color="text.primary">{name}</Typography>{' '}
      {/* chec if Gym name it's ok or just use the mail h1 */}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
