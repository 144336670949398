import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Badge,
  Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import TranslateIcon from '@mui/icons-material/Translate';

import NavbarProfileMenu from 'components/NavbarProfileMenu';
import NavbarMobileMenu from 'components/NavbarMobileMenu';

import './Navbar.scss';
import NavbarTranslationsMenu from 'components/NavbarTranslationsMenu';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const MENU_ID = 'navbar__menu';
const MOBILE_MENU_ID = 'navbar__mobile-menu';
const TRANSLATE_MENU_ID = 'navbar__translate-menu';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [trabslationsAnchorEl, setTranslationsAnchorEl] = useState(null);

  const { t } = useTranslation();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isTranslationsMenuOpen = Boolean(trabslationsAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleTranslateMenuOpen = (event) => {
    setTranslationsAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleTranslationsMenuClose = () => {
    setTranslationsAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Link to="/" className="navbar__link">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              RockRoutes
            </Typography>
          </Link>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={t('navbar.search')}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              sx={{ marginRight: '0.5rem' }}
              size="large"
              aria-label="translate button"
              color="inherit"
              onClick={handleTranslateMenuOpen}
            >
              <Badge color="error">
                <TranslateIcon />
              </Badge>
            </IconButton>
            <Link to="/map" className="navbar__link">
              <IconButton
                sx={{ marginRight: '0.5rem' }}
                size="large"
                aria-label="map button"
                color="inherit"
              >
                <Badge color="error">
                  <TravelExploreIcon />
                </Badge>
              </IconButton>
            </Link>
            <Divider orientation="vertical" variant="middle" flexItem />
            <IconButton
              sx={{ marginLeft: '0.5rem' }}
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={MENU_ID}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={MOBILE_MENU_ID}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <NavbarMobileMenu
        mobileAnchorEl={mobileMoreAnchorEl}
        mobileMenuId={MOBILE_MENU_ID}
        isMobileMenuOpen={isMobileMenuOpen}
        handleMobileMenuClose={handleMobileMenuClose}
        handleProfileMenuOpen={handleProfileMenuOpen}
        handleTranslateMenuOpen={handleTranslateMenuOpen}
      />
      <NavbarProfileMenu
        anchorEl={anchorEl}
        menuId={MENU_ID}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
      />
      <NavbarTranslationsMenu
        anchorEl={trabslationsAnchorEl}
        menuId={TRANSLATE_MENU_ID}
        isMenuOpen={isTranslationsMenuOpen}
        handleMenuClose={handleTranslationsMenuClose}
      />
    </Box>
  );
};

export default Navbar;
