import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, IconButton, Badge } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import AccountCircle from '@mui/icons-material/AccountCircle';
import TranslateIcon from '@mui/icons-material/Translate';

const NavbarMobileMenu = ({
  mobileAnchorEl,
  mobileMenuId,
  isMobileMenuOpen,
  handleMobileMenuClose,
  handleProfileMenuOpen,
  handleTranslateMenuOpen,
}) => {
  const { t } = useTranslation();

  return (
    <Menu
      anchorEl={mobileAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link to="/" className="navbar__link">
        <MenuItem>
          <IconButton size="large" color="inherit">
            <Badge color="error">
              <HomeIcon />
            </Badge>
          </IconButton>
          <p>{t('navbar.home')}</p>
        </MenuItem>
      </Link>
      <Link to="/map" className="navbar__link">
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge color="error">
              <TravelExploreIcon />
            </Badge>
          </IconButton>
          <p>{t('navbar.map')}</p>
        </MenuItem>
      </Link>
      <MenuItem onClick={handleTranslateMenuOpen}>
        <IconButton
          size="large"
          aria-label="translations button"
          aria-haspopup="true"
          color="inherit"
        >
          <TranslateIcon />
        </IconButton>
        <p>{t('navbar.translations')}</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{t('navbar.profile')}</p>
      </MenuItem>
    </Menu>
  );
};

export default NavbarMobileMenu;
